<template>
 <div >

     <XModal name="bvx2" :resizable="true"  width='80%' height="66%" :draggable="false" >
       <div :style="getAppStyle()+'height: 100%;border: 2pt inset grey;'">
         <div class='appHead'>
             <div style='display: inline-flex; color: #888; font-weight: bold; padding-right: 20pt; font-size: 10pt;'>
             </div>
              <button id='closePopup2' type="button" class="iButton" @click='maximize()' aria-label="Maximize">


              </button>
              <button id='closePopup2' type="button" class="iButton" @click='close' data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
			  </span>
              </button>
            </div> <!-- appHead -->
       <div class="modal-header">
          <slot name="header">
            <div class='appHeadLine'>
            
            	<div class='appLeft' style="font-weight: normal;">
				
            	{{printTime(slotData.time,":",true,false)}} <b>{{slotData.code}}</b>  ({{slotData.program}}) 
            	
                </div>
                <div class='appRight'>
                
             
                </div>
            </div> <!-- appHeadLine -->
            
           </slot>
        </div> <!-- modal-header -->  	  
        <div class="modal-body" :style='getMainContentStyle()'>
          <slot name="body">
            <div class='mainContent' >
             <div class='transition' :style='getAppStyle()+getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
					Price: {{ slotData.price }} for {{duration}}"  <br/> 
					Break-Capacity: {{ printTime(getAdTime(slotData.capacity),":",true,true) }}<br/>   
					Consumed: {{ printTime(slotData.capacity-slotData.available,":",true,true) }}   <br/> 
					Available: <span v-if='negative(slotData.available)' 
					             style='padding-left:5pt; padding-right:5pt; font-weight: bold; color: #000; background-color: #fcc;'>
					             {{ printTime(slotData.available,":",true,true) }}   
					           </span>
					           <span v-else style='padding-left:5pt; padding-right:5pt; color: #000; background-color: #cfc;'>
					             {{ printTime(slotData.available,":",true,true) }}     
					           </span>
					          <br/>  
							  
			    	<hr/> 
					<!--   
					Grid-Capacity: {{ printTime(getAdTime(breakData.detail.adTime),":",true,true)}}<br/> 
					Consumed: {{ printTime(breakData.consumedSecondsGrid,":",true,true) }}   <br/>  
					Available: <span v-if='negative(getAdTime(breakData.detail.adTime)-breakData.consumedSecondsGrid)' 
					            style='padding-left:5pt; padding-right:5pt; font-weight: bold; color: #000; background-color: #fcc;'>
					             {{ printTime(getAdTime(breakData.detail.adTime)-breakData.consumedSecondsGrid,":",true,true) }}   
					           </span>
					           <span v-else style='padding-left:5pt; padding-right:5pt; color: #000; background-color: #cfc;'>
					             {{ printTime(getAdTime(breakData.detail.adTime)-breakData.consumedSecondsGrid,":",true,true) }}   
					           </span>
					          <br/>    
			    	<hr/>
					-->
			    	
			  <div class='BMappHead' 
			             tooltip='identity("to add one of these columns, drag it to the header and drop it")'
			             @drop="removeColumn($event)" 
						 @dragover.prevent
						 @dragenter.prevent >
              	<div class="colored" @click='setAvColumnsVisible(1-avColumnsVisible)'>
              		<div style='vertical-align: middle; float: left; display: inline-flex; color: #888; font-weight: bold; padding-right: 5pt; font-size: 10pt;'>
              			<span style='padding-top:5pt; padding-left: 5pt;' class='dontWrap90 smallText'><b>Available Columns</b></span>
             		</div>
             		  <button id='showAvColumns' type="button" class="iButton" >
             		  <svg v-show='!avColumnsVisible' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
						  <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
					  </svg>
					  </button>
		              <button id='closeAvColumns' type="button" class="iButton" >
		              <svg v-show='avColumnsVisible' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
						  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
						  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
						</svg>
		              </button>
                  </div>
               </div> <!-- appHead -->
			    	
		    	<div  style='float: left; border: 3pt groovy #ccc; width: 100%;' class='transition'>		 
			    	<div v-for="(col,idx) in notSelectedColumns()" :key="'nsc_'+idx" style='display: block; width: 95%; padding-left: 5%;' class='transition'>
			    		<div :class='getAvColumnClasses()'   draggable
			    	         @click="addColumn(col.show, -1)"
			    	         @dragstart="startDragColumn($event, col.show, -1)"
			    	         > 
					       		{{ col.name }}
					       	</div>
			    	</div>
			    	
			    </div>
			    </div> <!-- CONTROLS -->
    		</div>
    
    		<!-- MAIN -->
            <div class='transition'  style='float: left; width: 81%;'> 
			    <!-- HEADER -->
			    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
				    <div style='float: top;'>
					    <div v-for="(col,idx) in selectedColumns" :key="'c_'+idx" class='floatLeft' :style='getColumnStyle(col)'
					        @dragstart="startDragColumn($event, col.show, idx)" draggable
					        @drop="onDropColumn($event, idx)"
					        @dragover.prevent
							@dragenter.prevent>
					       <div class='header higher'>
					       		<div v-show="!col.buttons"> {{ col.name }}</div>
					       	</div>
					    </div>
					   
				    </div>  <!-- float: top -->   
				</div> <!-- HEADER --><!-- spotRClicked($event, spot) -->
				
				<div style='width: 100%; height: calc(50vH); overflow-y: scroll;'>
					
			    	<div style='float: top; width: 100%;height: 100%; width: 100%;'>
			    	    <div v-for="(spot,idx) in spots" :key="'s_'+idx" class="todrag"
			    	         @contextmenu.prevent="$event.stopPropagation()" 
			    	         @drop="onDrop($event, idx)"
					         @dragover.prevent
							 @dragenter.prevent
			    	         draggable 
			    	         @dragstart="startDrag($event, spot, idx)">			    	         
			    	        <div v-for="(col,idx2) in selectedColumns" :key="'col_'+idx+'_'+idx2" class='floatLeft' :style='getColumnStyle(col)'>
						        <div v-if="col.buttons" class='spotDetail'>
						       		<button @click='deleteSpot(spot)' class='iButton'>
						       		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#aaa" class="bi bi-trash" viewBox="0 0 16 16">
									  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
									  <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
									</svg>
						       		</button>
						       	</div>
						       <div v-else class='spotDetail dontWrap clipped' :style="(spot.statusInPlaylist?'': ('color: '+options.unplacedColor+';'))">
						       		{{ print(spot, col) }}
						       	</div>
						    </div> 

			    	    </div>
			    	   	
			    	</div> 
		    	</div>  <!-- TABLE -->
            </div> <!-- float: left -->
			</div> <!-- mainContent -->
	      </slot>
	    </div> <!-- modal-body -->
	    </div>
	  </XModal> <!-- modal-container -->  	  
	
	<ContextMenu ref="menu" offsetY=120>
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="rebookSpots($event)">
          Run Copy Rotation
        </ContextMenuItem>
        <ContextMenuSep/>
        <span v-for="stati in bookingTypes"  :key='stati.id'>
        <ContextMenuItem v-if="stati.id!=contextData.statusId" @clicked="changeType($event, stati, contextData)">
          {{ stati.name }}
        </ContextMenuItem>
        </span>
       </template>
    </ContextMenu>
 
 </div>
</template>

<script>
import {HTTP, fwAPI, invAPI, bngAPI, setReload, myLocale, formatNumber } from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2 } from '@/basicTimeFN.js';
import { getAppStyle, initAppMode, isDarkMode } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';

import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: 'GFW Grid Break View',
  components : {
   ContextMenu,ContextMenuItem,ContextMenuSep
  },
  props: {
    reference: String,
    time: Number,
    display: Boolean,
    options: Object
  },
  data () {
    return {
        gridAds: {},
		slotData: {},
        spots: [],
		width: 70,
        height: 120,
        mainHeight: 95,
        unit: 15,
        zoom: 15,
		duration: 0,
        zoomArr: [10,12,14,15,16,18,20,22],
        grid: [],
        bookingTypes: [],
        weekdayLabel: [],
        withAds: true,
        starttime: 0,
        dayStartAt: 0,
        controlsRight: true,
       
        mapping: [],
        metadata: {},
        dataId: 0,
        breakData: {},
        user: {},
        selectedRecord: {},
        selectedIndex: 0,
        allEntities: [],
        showEditor: false,
        breakReference: {},
        widthCalculated: 100,
        avColumnsVisible: 0,
        getAppStyle,
        availableColumns: [
	        {name: "Pos", show: "position", w: 3},
	        {name: "Client", show: "clientName", w: 20},
	        {name: "Campaign", show: "campaignName", w: 20},
	        {name: "Placement", show: "placementName", w: 10},
	        {name: "From", show: "placementFrom", w: 10},
	        {name: "To", show: "placementTo", w: 20},
	        {name: "Inventory", show: "inventory", w: 20},
	        {name: "Product", show: "productName", w: 15},
	        {name: "ProductFamily", show: "productFamily", w: 15},
	        {name: "ProductGroup", show: "productGroup", w: 15},
	        {name: "Dur", show: "duration", w: 5},
	        {name: "Copy", show: "copyName", w: 20},
	        {name: "HouseNumber", show: "copyHouseNumber", w: 15},
	        {name: "Code", show: "copyCode", w: 15},
	        {name: "Gross", show: "gross5", w: 10},
	        {name: "Fixed", show: "gross4", w: 10},
	        {name: "Ratecard", show: "gross3", w: 10},
	        {name: "Ratecard 30", show: "gross1", w: 10},
	        {name: "Status", show: "statusName", w: 10},
	        {name: "Created", show: "created", w: 10},
	        {name: "Valid", show: "statusValid", boolean: true, w: 10},
	        {name: "SalesUnit", show: "salesUnit", w: 10, align: 'L' },
	        {name: "InPlaylist", show: "statusInPlaylist", boolean: true, w: 10},
	        {name: "Gross", show: "gross5", w: 10},
	        {name: "(Action)", show: "-", w: 5, buttons: true}
        
        ],
        selected: ["Pos", "Client", "Campaign", "Product", "Dur", "Copy", "Gross", "(Action)"],
        selectedColumns: [
	        
        ],
        positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  /////
 
  /////
  methods: {
     getContentStyle()  { return "width: "+ this.width+"% !important;height: "+ this.height+"% !important;"; },
     getAvColumnClasses()  { if(!this.avColumnsVisible) { return "invisible transition" } else { return "choice normal floatLeft button transition"}},
     myLocale() { return myLocale();},
      getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100% - 200px); padding: 1ex;"
    },
     dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },
    spotRClicked(event, spot)
     {
        event.preventDefault();
        this.$refs.menu.open(event, spot )
     },
     getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
     incWidth() { if ( this.width < 90) { this.width *= 1.1; this.height*=1.1;} },
     decWidth() { if ( this.width > 20) { this.width *= 0.9; this.height*=0.9;} },
     maximize() { if ( this.width == 100) { this.width = 70; this.mainHeight=80, this.height=90; } else { this.width=100; this.mainHeight=70, this.height=100; }},
     gridHeight() { return "height: "+this.zoom+"pt;";},
     hasTimezone() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasGenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     hasSubgenre() { return  ( this.timezoneLocal && this.timezoneLocal.id ); },
     identity(x) { return x },
     setAvColumnsVisible( val) { this.avColumnsVisible = val},
     open(reference, slot, duration) { 
        
     	this.breakReference = reference; 
		this.slotData = slot;
		this.duration = duration;
     	this.getSpots();
     	
     },
     close() { this.breakReference = null; this.$modal.hide('bvx2') },
     getBreakDuration(spots)
     {
     	var dur = 0;
     	for ( var s in spots )
     	{
     		if ( spots[s].statusInPlaylist )
     		{
     			dur += spots[s].duration;
     		}
     	}
     	return dur;
     },
     notSelectedColumns()
     {
     	var cols = new Array();
     	for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i].show;
     		var selected = false;
     		for ( var vis in this.selectedColumns )
     		{
     			let colSel = this.selectedColumns[ vis].show;
     			if ( colSel === col )
     			{
     				selected = true;
     				break;
     			}
     		}
     		if ( ! selected )
     		{
     			cols.push( this.availableColumns[ i]);
     		}
     	} 
     	return cols;
     },
     print(spot, col)
     {
     	let val = spot[ col.show ];
     	if ( col.boolean )
     	{
     		if ( val )
     		{
     			return "X";
     		}
     		return "";
     	}
     	return formatNumber( val )
     },
     getColumnStyle(col)
     {
     	return "width: " + (parseInt(col.w)/this.widthCalculated*100) +"% !important; font-size:"+this.options.fontSize+"px";
     },
     getAdSpaceSection( no )
     {
     	if ( no === 0 ) { return "Section BEFORE" }
     	else if ( no === 999 ) { return "Section AFTER" }
     	else { return "Inside-Section "+no }
     },
     getAdTime(time)
     {
     	let secs = time;
     	return time;
     	
     },
     calculatePrice(dur)
     {
     	let base = this.gridAds.rateCard.price/this.gridAds.rateCard.duration;
     	var returnValue = 0;
     	for (var pi in this.gridAds.priceIndexes )
     	{
     		let index = this.gridAds.priceIndexes[pi];
     		if ( index.durFrom <= dur && index.durTo >= dur )
     		{
     			returnValue = index.index/100*base*(dur*index.factor+index.value);
     			break;
     		}
     	} 
     	if ( this.gridAds.priceIndex.rounding )
     	{
     		return Number(Math.round(returnValue)).toLocaleString(myLocale())
     	}
       return Number(Math.round(returnValue*100)/100).toLocaleString(myLocale())
     },
     
    update() {
      //
    },
    
     timezoneStyle() {
        if ( this.hasTimezone() )
        {
    		return "float: left; width: 10%  !important; background-color: #eee; color: #444;";
    	}
    	return "float: left; width: 0pt !important; background-color: #eee; color: transparent;";
    },
     weekDayIndexes() {
            var intArr = [];
            for ( var i=0; i < this.weekdayLabel.length; i++ )
            {
                intArr.push((i + parseInt(sessionStorage.weekStart)) % 7);
            }
            return intArr;
        },
     printWeekdays(wd) {
          let p2 = 1;
          var x = [];
          for ( var ii = 0; ii < 7; ii++ )
          {
            if ((wd & p2) == p2)
            {
            	x[ii] = true;
            }
            else
            {
            x[ii] = false;
            }
            p2 = p2 * 2;
          }
          var retString = "";
          //alert( x +" / "+ this.weekDayIndexes() +" // "+ this.weekdayLabel);
          let arr = this.weekDayIndexes();
          let cnt = 0;
          for ( ii in arr )
          {
            let idx = arr[ii]
          	retString += ( x[idx] ) ? this.weekdayLabel[idx]: "-";
          	if ( cnt < 6 )
          	{
          		retString += ",";
          	}
          	cnt++; 
          }
          //alert( x +" / "+ this.weekDayIndexes() +" / "+ this.weekdayLabel +" --> " + retString);
          return retString;
     },
    
      fmtTimePart( aTime) {
      	if ( aTime < 10 ) 
      	{
      		return "0"+aTime;
      	}
      	return ""+aTime;
      },
      mainTime(time) { 
        let ss = time % 60;
        let mm = ((time - ss) / 60 ) % 60;
        let hh = ((time - ss - mm * 60) / 3600 ) % 60;
        if ( hh >= 24 )
        {
        	return "height: "+this.zoom+"pt; background-color: #e0e0ff;";
        }
      	return "height: "+this.zoom+"pt;";
      },
      negative(time)
      {
        return ( time < 0 )
      },
      printTime(time,sep,withSec,noHour)
      {
      	return printTimeOpt( time,sep,withSec,noHour)
      },
     
      printTime2(time)
      {
        return printTime2(time);
       },
    gridStyle(grid) {
    	var style = "top: ";
    	var height = grid.detail.duration * this.zoom / this.unit; 
    	var start = (grid.detail.starttime-(this.dayStartAt))/60* this.zoom / this.unit
    	style += start+"pt; height: "+height+"pt;";
    	//alert( start + "/"+this.dayStartAt );
    	if ( grid.rateCard )
    	{
    		style += "background-color: "+grid.rateCard.colour+";";
    	}
    	return style;
    },
    adSpaces( grid)
    {
    	if ( !this.withAds  )
    	{
    		//return [];
    	}
    	return grid.adspace;
    },
    getSpots( )
    {
    	let that = this;
        if( this.breakReference)
        {
            
	        let that = this;
	        let time = new Date().getTime();
	    	let spotReader = new Promise(function (resolve, reject) {
		        //console.log(bngAPI+"/findAllSpotsForBreakLines/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
				let refArr = new Array();
				//alert( JSON.stringify( that.slotData))
				if ( that.slotData.breakId )
				{
					refArr.push( that.slotData.breakId);
				}
		        else
				{
					refArr.push( that.breakReference);
				}
		        
		        HTTP.post( bngAPI+"/findAllSpotsForBreakLines/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, refArr)
	        		 .then( response => 
	                 {  
	                    let dur = (new Date().getTime()-time);
	                    
		                console.log("loading grid "+response.data[0].spotsGrid.length+" spots in " + dur +"ms");
	                    that.spots = response.data[0].spots;
						
	                    that.user = response.data[0].user;
	                    that.bookingTypes = response.data[0].bookStatus;
						//alert( JSON.stringify( that.bookingTypes));
	                    that.breakData = response.data[0];
	                    if ( that.user && that.user.spotColumns )
	                    {
	                    	that.selected = that.user.spotColumns.split(',');
	                    }
	                    that.arrangeColumns();
						//alert( JSON.stringify(that.selected));
						
	                    resolve (response.data[0]);
	                        
	                 }).catch(e => {
	                        
	                        that.$toast.error( e.response.data, 'Error', { position: "topRight" });
	                        console.log( JSON.stringify(e));
	                        reject ();
	                    });
		      });
		      spotReader.then ( data => 
		      {

		      	that.$modal.show('bvx2'); 

		      });
		 }
    },
    adLabel(ad) {
        var text = "";
        if ( ad.section == 0 )
        {
        	text = "OPENER";
        }
        else if ( ad.section == 2 )
        {
        	text =  "CLOSER";
        }
        else
        {
        	text =  "CENTER-"+ad.no;
        }
        return text;
    },
    adStyle(grd, ad) {
            	
    	var height = 6 * this.zoom / this.unit; 
    	if ( !this.withAds  )
    	{
    		return "background-color: transparent; color: transparent;";
    	}
    	if ( height > 16 )
    	{
    		height = 14;
    		
    	}
    	var fontSize = 1+height/2;
    	if ( fontSize < 7 )
    	{
    		fontSize = 7;
    	}
    	var start = ad.estStarttime;
    	
    	start = ((start - grd.detail.starttime))/60* this.zoom / this.unit;
    	if ( ad.section == 0 )
        {
        	start = -height
        } 

    	var style =  "top: " + start+"pt; font-size: "+fontSize+"pt;";
    	style += "border-top: .5pt solid #aaa;";
    	//console.log( grd.detail.name +"-"+ad.no+"/"+ad.estStarttime+"/"+grd.detail.starttime+"/"+this.dayStartAt+" -> "+style);
    	return style;
    },
    startDrag (evt, spot, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('spotIndex', idx);
        var array = new Array();
        array.push( spot);
        evt.dataTransfer.setData('spots', JSON.stringify(array))
  	},
  	startDragColumn (evt, col, idx) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('column', col)
        evt.dataTransfer.setData('columnIndex', idx)
  	},
  	deleteSpot(spot)
  	{
		var toDelete = new Array();
		toDelete.push(spot.id);
  		this.$emit("deleteSpot", toDelete);	
  	},
  	changeType(event, type, spot)
     {
        try {
        this.$refs.menu.close();
        } catch (e) { 
        //
        } 
     	var mySpots = new Array();
     	mySpots.push(spot);
     	this.$emit("setTypeForSpots", mySpots, type);
     	
     },
  	onDrop (evt, target) {
		const source = evt.dataTransfer.getData('spotIndex')

		let that = this;
		let time = new Date().getTime();
		let n = 1;
		
		let move = this.spots[ source];
        if ( target < source )
        {
        	this.spots.splice(source, 1);
        	this.spots.splice(target, 0, move);
        }
                
                
        if ( target > source )
        {
        	this.spots.splice(target+1, 0, move);
        	this.spots.splice(source, 1);
        }
        //this.spots[ target] = this.spots[ source];
        //this.spots[ source] = replace;
        for ( var p in this.spots)
        {
            this.spots[p].position = parseInt(p)+1;
        }

		
		console.log(bngAPI+"/arrangeSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId);
		HTTP.put( bngAPI+"/arrangeSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, this.spots)
            .then( response => 
            { 
                let dur = (new Date().getTime()-time);
                
                console.log("done "+n+" bookings in " + dur +"ms => "+(dur/n)+"ms/spot");
                that.getSpots(this.reference);
                
            }).catch(e => {
                    that.$toast.error(e.response.data, 'Error', { position: "topRight" });
					that.getSpots(this.reference);
            });
		
	},
	removeColumn (evt )
	{
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		if ( columnIndex && columnIndex >= 0 ) 
		{
			this.selectedColumns.splice( columnIndex, 1);
		}
		this.calcWidths( true );
	},
	onDropColumn (evt, target) {
		const source = evt.dataTransfer.getData('column');
		const columnIndex = evt.dataTransfer.getData('columnIndex');
		var colToInsert;
		for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i];
     		if ( col.show == source )
     		{
     			colToInsert = col;
     			break;
     		}
     	}
     	if ( colToInsert )
     	{
	     	//alert( target + " vs " + columnIndex);
	     	if ( target <= columnIndex )
	     	{
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
				this.selectedColumns.splice( target, 0, colToInsert);
			}
			else
			{
				this.selectedColumns.splice( target+1, 0, colToInsert); // insert at 6
				if ( columnIndex && columnIndex >= 0 ) 
				{
					this.selectedColumns.splice( columnIndex, 1);
				}
			}
			
			this.calcWidths( true );
		}
	},
	calcWidths(withSaving)
	{
		let wTot = 0;
		var columns = "";
		for ( var i in this.selectedColumns )
     	{
     		wTot += this.selectedColumns[ i].w;
     		if ( columns )
     		{
     			columns += "," + this.selectedColumns[ i].name;
     		}
     		else
     		{
     			columns = this.selectedColumns[ i].name;
     		}
     	}
     	if ( withSaving )
     	{
	     	var saveUser = 0;
	     	if ( this.user && ! this.user.spotColumns)
	     	{
	     		saveUser = 1;
	     		this.user.spotColumns=columns;
	     	}
	     	if ( this.user && this.user.spotColumns != columns)
	     	{
	     		saveUser = 1;
	     		this.user.spotColumns=columns;
	     	}
	     	if ( saveUser )
	     	{
	     		this.$emit("saveUser", this.user);
	     	}
		}
     	this.widthCalculated = wTot;
	},
	arrangeColumns()
	{
	    this.selectedColumns = new Array();
		for ( var i in this.selected )
		{
			let colName = this.selected[i];
			for ( var j in this.availableColumns )
			{
				let column = this.availableColumns[j];
				if ( column.name == colName )
				{
					this.selectedColumns.push( column );
					break;
				}
			}
	    }
	    this.calcWidths( false);
	},
	addColumn (show) {
		
		var colToInsert;
		for ( var i in this.availableColumns )
     	{
     		let col = this.availableColumns[ i];
     		if ( col.show == show )
     		{
     			colToInsert = col;
     			break;
     		}
     	}
     	this.selectedColumns.splice( this.selectedColumns.length-1, 0, colToInsert); // insert at 6
     	
		this.calcWidths( true);
	}
  },
  watch: {

	
  },
  created() {
	this.$modal.show('x2');
  }
}
</script>
<style scoped>
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.header { 
  display: flex;
  align-items: center;
  justify-content: center;

  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  
}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
.colored {
  background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.higher {
  height: 28pt; 
  font-weight: bold; 
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

}
.dontWrap {
	
	overflow-x: hidden;
	white-space: nowrap; /* Don't forget this one */
	text-overflow: ellipsis; 
}
.todrag {
 display: block;
 width: 100%; 
 height: 16pt;
 border-left: 1pt solid #ccc; 
 border-bottom: 1pt solid #ccc;
}
.floatLeft {
    float: left;
}
.appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
} 
.appRight {
	display: inline-table; 
	text-align: right;
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	width: 30%; 
}
.appHead { 
    width: 100%;  
    text-align: right;
    background-color: #eef;
    padding: 0pt 0pt 0pt;
 }
.iButton {
    background-color: transparent;
    border-color: #dbdbdb;
    border-width: 0px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
 
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  //display: table-cell;
  
}

.modal-container {
    
  padding-bottom: 20pt;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //transition: all 0.4s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
  padding: 5pt;
  height: 100%;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.gridMainBV { position: absolute; z-Index: 2; text-align: center; width: 99% ; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
.adMainBV { 
  float: top;
  position: absolute; 
  align-items: center;
  justify-content: center;
  z-Index: 17; 

  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 
  border: .5pt solid black;
    opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s;
}
.adMainInnerRight { 
  color: #000; 
  width: 60%;
  text-align: left !important;
  //border-bottom: .5pt solid #ddd;
  padding-left: 5pt;
  background-color: rgba(255, 255, 255, .6);
  //border-left: .5pt solid #ddd;
    opacity: 1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.adMainInnerLeft { 
  align-items: center;
  justify-content: center;
  float: left top;
  z-Index: 17; 
  padding-top: 2pt;
  font-size: 9pt;
  background-color: rgba(255, 255, 255, .6);
  font-weight: bold;
  margin-left: 2pt; 
  margin-right: 4pt; 
  color: #666; 
  height: 100% !important;
  
    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
ul {
  list-style: square inside none;
}
.gBold { 

}
.choice { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  
  width: 100% !important; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
}
.invisible {
   display: flex;
   height: 0pt;
   color: transparent;
   align-items: center;
   justify-content: center;
   background-color: transparent; 
   border-left: 0pt solid #fff; 
   border-bottom: 0pt solid #fff;
}
.colored {
  //background-color: #eef; 
 }
.normal {
  height: 16pt; 
}
.small { font-size: 8pt }
.smaller { font-size: 10pt }
.tiny { font-size: 7pt }
#draggable-container {
  position: absolute;
  z-index: 9;
}
#draggable-header {
  z-index: 10;
}
</style>
